import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { createEmployeeVacationsAction, updateEmployeeVacationsAction } from "../../actions";
import { useDispatch } from "react-redux";

const gridStyle = { height: 75, padding: "0px 10px" };

const CreateEditDialog = (props) => {

  const {
    open,
    data,
    userCode,
    vacationsSchema,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createEmployeeVacationsAction({ ...dataToSend, userCode }))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updateEmployeeVacationsAction({ ...dataToSend, userCode }))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, vacationsSchema, create, modify, openValidationError);

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {isCreate ? "Crear registro" : "Editar registro"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                fullWidth
                required
                disabled={isCreate ? false : true}
                margin={"none"}
                label={'Año'}
                value={formData.year}
                onChange={(e) => setFormValue(e.target.value, "year")}
                inputProps={{ maxLength: 9 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label={'Meses'}
                value={formData.month}
                onChange={(e) => setFormValue(e.target.value, "month")}
                inputProps={{ maxLength: 2 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label={'Días legales'}
                value={formData.legalDays}
                onChange={(e) => setFormValue(e.target.value, "legalDays")}
                type='number'
                inputProps={{ min: "0", step: "0.001" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                fullWidth
                margin={"none"}
                label={'Días adicionales'}
                value={formData.additionalDays}
                onChange={(e) => setFormValue(e.target.value, "additionalDays")}
                type='number'
                inputProps={{ min: "0", step: "0.001" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                fullWidth
                margin={"none"}
                label={'Otros'}
                value={formData.otherDays}
                onChange={(e) => setFormValue(e.target.value, "otherDays")}
                type='number'
                inputProps={{ min: "0", step: "0.001" }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label={'Descripción'}
                value={formData.description}
                onChange={(e) => setFormValue(e.target.value, "description")}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default CreateEditDialog;