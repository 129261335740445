import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { executeSpecialProcessAction } from "@icarius-pages/specialProcessExecution/actions";
import { getLoading } from "@icarius-pages/specialProcessExecution/selectors";

const useSpecialProcessExecution = () => {

  const dispatch = useDispatch();

  const isLoading = useSelector(getLoading);

  const [paramsToUse, setParamsToUse] = useState(null);
  const [paramDialogIsOpen, setParamDialogIsOpen] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(null);

  const handleAskSpecialProcessExecution = (process) => {
    setSelectedProcess(process);
    
    dispatch(openDialogAction({
      title: "Atención",
      msg: `¿Está seguro que desea ejecutar el proceso: ${process.name}?`,
      onConfirm: () => handleStartSpecialProcessExecution(process),
      onCancel: () => handleCloseParamDialog(),
    }));

  }

  const handleStartSpecialProcessExecution = (process) => {
    if (process?.parameters?.length) {
      //abrir modal de setparam
      setParamDialogIsOpen(true);
      setParamsToUse(process.parameters);
      return;
    }

    // si no tiene parametros
    executeSpecialProcess(null, process);
  }

  const handleCloseParamDialog = () => {
    setParamDialogIsOpen(false);
    setParamsToUse(null);
    setSelectedProcess(null);
  }

  const executeSpecialProcess = (param, process) => {
    const processToUse = process || selectedProcess;

    dispatch(executeSpecialProcessAction({
      process: processToUse.code,
      parameters: param || [],
    })).then(() => {
      handleCloseParamDialog();
    })
  }

  const specialProcessExecutionData = {
    isLoading,
    selectedProcess,
    paramsToUse,
    paramDialogIsOpen,
  }

  const specialProcessExecutionFunctions = {
    handleAskSpecialProcessExecution,
    executeSpecialProcess,
    handleCloseParamDialog,
  }

  return {
    specialProcessExecutionData,
    specialProcessExecutionFunctions,
  }
}

export default useSpecialProcessExecution;
