/* eslint-disable array-callback-return */
import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getKPIDataAPI,
} from "@icarius-connection/api";

export const getKPIDataAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getKPIDataAPI(`?code=${code}`);
    let data = response.data && response.data.data;

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};