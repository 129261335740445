import { useEffect } from "react";
import paths from "@icarius-localization/paths";
import { useHistory, useLocation } from "react-router-dom";
import { getUserData, getUserMenuData } from "src/app/selectors";
import { useDispatch, useSelector } from "react-redux";
import { getKPIDataAction } from "../actions";
import { getIsLoading, getKPIData } from "../selectors";
import { RESET_STATE } from "../actionTypes";

const useUserMenu = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { level } = useSelector(getUserData);
  const userMenuData = useSelector(getUserMenuData);
  const isLoading = useSelector(getIsLoading);
  const kpiData = useSelector(getKPIData);

  const index = new URLSearchParams(location.search).get("index");
  const currentMenu = userMenuData[index];

  useEffect(() => {
    // si no tiene parametro o userMenuData, redireccionar al home (porque no habria nada para mostrar)
    if (!location.search || !userMenuData) history.push('/');
  }, [location.search, history, userMenuData])

  const handleOpenEditor = (item) => {
    history.push({
      pathname: item.tableData.type === 'S' ? paths.systemTables : paths.userTables,
      search: `code=${item.tableData.tableCode}&codver=${item.tableData.codver}&hideGoBack=Y`,
    });
  }

  const handleOpenQuery = (item) => {
    history.push({
      pathname: level !== "E" ? paths.myQueries : paths.queries,
      search: `?group=${item.code}&queryCode=${item.queryData.code}&groupName=${currentMenu.name}`,
    });
  }

  const handleOpenGeoanalysis = (item) => {
    history.push({
      pathname: paths.geoanalysis,
      search: `?group=${item.code}&queryCode=${item.geoqueryData.code}&groupName=${currentMenu.name}`,
    });
  }

  const handleOpenKPI = (item) => {
    dispatch(getKPIDataAction(item.kpiData.code));
  }

  const handleCloseKPIDialog = () => dispatch({ type: RESET_STATE });

  const handlers = {
    handleOpenEditor,
    handleOpenGeoanalysis,
    handleOpenQuery,
    handleOpenKPI,
    handleCloseKPIDialog,
  }

  return {
    isLoading,
    currentMenu,
    kpiData,
    handlers,
  }
}

export default useUserMenu;