import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      "functionCode",
      "functionName",
      "order",
      "image",
      "siblingPath",
      "name",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {

    const functionCodeRegex = /^[A-Z][A-Z0-9_]*/;
    const functionNameRegex = /^[A-Z][-A-Z0-9ÑÁÉÍÓÚ()]*/;
    const nameRegex = /^[a-zA-Z][a-zA-Z0-9ÑÁÉÍÓÚ]*/;

    let schema = yup.object().shape({
      functionCode: yup.string().matches(functionCodeRegex).required(),
      functionName: yup.string().matches(functionNameRegex).required(),
      name: yup.string().matches(nameRegex).required(),
      siblingPath: yup.string().required(),
      order: yup.number().min(1).required(),
      image: yup.string(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = { ...formData };

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (fieldName === "functionCode") {
      return value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }

    if (fieldName === "functionName") {
      return value.length > 0 ? value.toUpperCase() : "";
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
