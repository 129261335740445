import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import useTemplates from "@icarius-common/templates/components/useTemplates";
import TemplatesHeader from "@icarius-common/templates/components/templatesHeader";
import CreateTemplateDialog from "@icarius-common/templates/components/createTemplateDialog";
import useVacations from "./useVacations";
import EmployeeVacationsDialog from "./employeeVacationsDialog";
import GraphsDialog from "./graphsDialog";

const Vacations = () => {

  const {
    isLoading,
    data,
    debitData,
    creditData,
    miscData,
    vacationsSchema,
    dateFormat,
    locale,
    state,
    handlers,
  } = useVacations();

  const {
    user,
    templates,
    selectedTemplate,
    isLoadingTemplates,
    createTemplateDialogIsOpen,
    getRole,
    handleCreateTemplate,
    handleImportTemplates,
    handleExportTemplates,
    handleSelectTemplateAndUpdateGrid,
    handleOpenCreateTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleCloseCreateTemplateDialog,
  } = useTemplates('VACATIONS');

  const customHeader = (gridRef) => user.level === "E" && (
    <TemplatesHeader
      gridRef={gridRef}
      user={user}
      templates={templates}
      selectedTemplate={selectedTemplate}
      getRole={getRole}
      handleImportTemplates={handleImportTemplates}
      handleExportTemplates={handleExportTemplates}
      handleOpenCreateTemplateDialog={handleOpenCreateTemplateDialog}
      handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
      handleOpenUpdateTemplateDialog={handleOpenUpdateTemplateDialog}
      handleSelectTemplateAndUpdateGrid={handleSelectTemplateAndUpdateGrid}
    />
  );

  const mailItem = (gridRef) =>
    <CustomIconButton
      title={'Enviar mensaje recordatorio'}
      onClick={() => handlers.handleOpenMailDialog(gridRef)}
      type={"mail"}
    />

  const graphicsItem = (gridRef) => Boolean(data.length) &&
    <CustomIconButton
      title={getLocalizedString("charts")}
      onClick={() => handlers.handleOpenGraphs(gridRef)}
      type={"graphics"}
    />

  const helpItems = [
    <MenuItemWithIcon
      key={"a"}
      value={"/resources/ICARIUS - Cuenta corriente de vacaciones.pdf"}
      text={getLocalizedString("ctaCteVacacionesInstructivo")}
      type={"file"}
    />,
  ]

  const child = (gridRef) =>
    <>
      {
        state.employeeVacationsDialogIsOpen &&
        <EmployeeVacationsDialog
          open={state.employeeVacationsDialogIsOpen}
          isLoading={isLoading}
          creditData={creditData}
          debitData={debitData}
          miscData={miscData}
          vacationsSchema={vacationsSchema}
          employeeCode={state.selectedRow.code}
          employeeName={state.selectedRow.name}
          dateFormat={dateFormat}
          handleClose={handlers.handleCloseSecondGrid}
        />
      }
      {
        state.graphsDialogIsOpen &&
        <GraphsDialog
          open={state.graphsDialogIsOpen}
          data={state.graphDialogData}
          handleClose={handlers.handleCloseGraphs}
        />
      }
      {
        createTemplateDialogIsOpen &&
        <CreateTemplateDialog
          open={createTemplateDialogIsOpen}
          level={user.level}
          handleCreateTemplate={(role, name) => handleCreateTemplate(gridRef, role, name)}
          handleCloseDialog={handleCloseCreateTemplateDialog}
        />
      }
    </>

  return (
    <CommonPage
      isLoading={isLoadingTemplates || isLoading}
      rowData={data}
      title={getLocalizedString("vacationsTitleLong")}
      gridTitle={getLocalizedString("vacationsTitleLong")}
      locale={locale}
      dateFormat={dateFormat}
      columnDefPage={paths.vacations}
      handleRowClick={handlers.handleRowClick}
      hasHelp
      helpItems={helpItems}
      customHeader={customHeader}
      menuItems={[graphicsItem, mailItem]}
      handleOpenGraphs={handlers.handleOpenGraphs}
      hasExpand
      hasSelectAll
      codeFieldName={'code'}
    >
      {child}
    </CommonPage>
  );
}

export default Vacations;