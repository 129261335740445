import React from "react";
import SetParamDialog from "@icarius-common/setParamDialog";
import ScreenSelector from "@icarius-common/screenSelector";
import Loader from "@icarius-common/loader";
import IndividualKPIDialog from "@icarius-pages/kpiIndicators/components/individualKPIDialog";
import useSpecialProcessExecution from "./useSpecialProcessExecution";
import useUserMenu from "./useUserMenu";

const UserMenu = () => {

  const {
    isLoading,
    currentMenu,
    kpiData,
    handlers,
  } = useUserMenu();

  const {
    specialProcessExecutionData,
    specialProcessExecutionFunctions,
  } = useSpecialProcessExecution();

  const data = (() => {
    if (!currentMenu || !currentMenu.items?.length) return [];

    return currentMenu.items.map((item) => {
      return ({
        name: item.name,
        image: item.image,
        ignorePathValidation: true,
        onClick: () => {
          if (item.processData) {
            specialProcessExecutionFunctions.handleAskSpecialProcessExecution(item.processData);
            return;
          }

          if (item.tableData) {
            handlers.handleOpenEditor(item);
            return;
          }

          if (item.queryData) {
            handlers.handleOpenQuery(item);
            return;
          }

          if (item.geoqueryData) {
            handlers.handleOpenGeoanalysis(item);
            return;
          }

          if (item.kpiData) {
            handlers.handleOpenKPI(item);
            return;
          }
        }
      })
    });
  })();

  const pageTitle = (() => {
    if (!currentMenu) return '';
    return currentMenu.name;
  })();

  return (
    <>
      <Loader open={specialProcessExecutionData.isLoading || isLoading} />
      <ScreenSelector
        title={pageTitle}
        data={data}
      />
      {
        specialProcessExecutionData.paramDialogIsOpen &&
        <SetParamDialog
          open={specialProcessExecutionData.paramDialogIsOpen}
          params={specialProcessExecutionData.paramsToUse}
          handleClose={specialProcessExecutionFunctions.handleCloseParamDialog}
          handleAgree={specialProcessExecutionFunctions.executeSpecialProcess}
        />
      }
      {
        Boolean(kpiData) &&
        <IndividualKPIDialog
          open={Boolean(kpiData)}
          data={kpiData}
          handleClose={handlers.handleCloseKPIDialog}
        />
      }
    </>
  );
}

export default UserMenu;