import NAME from "./constants";

export const GET = NAME + "/GET";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";
export const GET_REJECTED = NAME + "/GET_REJECTED";

export const EMPLOYEE_VACATIONS = NAME + "/EMPLOYEE_VACATIONS";
export const EMPLOYEE_VACATIONS_FULFILLED = NAME + "/EMPLOYEE_VACATIONS_FULFILLED";
export const EMPLOYEE_VACATIONS_REJECTED = NAME + "/EMPLOYEE_VACATIONS_REJECTED";

export const DOWNLOAD = NAME + "/DOWNLOAD";
export const DOWNLOAD_FULFILLED = NAME + "/DOWNLOAD_FULFILLED";
export const DOWNLOAD_REJECTED = NAME + "/DOWNLOAD_REJECTED";

export const SEND_MAIL = NAME + "/SEND_MAIL";
export const SEND_MAIL_FULFILLED = NAME + "/SEND_MAIL_FULFILLED";
export const SEND_MAIL_REJECTED = NAME + "/SEND_MAIL_REJECTED";

