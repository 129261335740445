import * as actionTypes from "./actionTypes";
import {
  getWorksAndTasksSettingsAPI,
  deleteWorksAndTasksSettingsAPI,
  createWorksAndTasksSettingsAPI,
  updateWorksAndTasksSettingsAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = "No puede existir más de una obra/faena que comparta el mismo código";
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getWorksAndTasksAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getWorksAndTasksSettingsAPI();

    let data = response.data && response.data.result;
    let centers = response.data && response.data.centerList;
    let projects = response.data && response.data.projectList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, centers, projects },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteWorksAndTasksAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteWorksAndTasksSettingsAPI(dataToSend);

    let data = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Obra o Faena se ha eliminado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    if(e.response.data.error === "CODE_IN_USE"){
      dispatch(openSnackbarAction({ msg: "No es posible eliminar la Obra o Faena porque está en uso", severity: "error" }));
    }else{
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));  
    }
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
  }
};

export const createWorksAndTasksAction = (permission) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createWorksAndTasksSettingsAPI(permission);

    let data = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Obra y Faena se ha creado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    if(e.response.data.error === "DUPLICATED_DATA"){
      dispatch(openSnackbarAction({ msg: "No puede existir más de una obra/faena que comparta el mismo código", severity: "error" }));
    }else{
      errorHandler(e, dispatch);
    }
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    
  }
};

export const modifyWorksAndTasksAction = (permission) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateWorksAndTasksSettingsAPI(permission);

    let data = response.data && response.data.result;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "Obra y Faena se ha actualizado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};