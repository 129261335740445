import React from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createWorksAndTasksAction, modifyWorksAndTasksAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const { data, centers, projects, handleClose } = props;
  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createWorksAndTasksAction(dataToSend))
    .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(modifyWorksAndTasksAction(dataToSend))
    .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* codigo */}
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}            
            label={"Código de obra/faena"}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 12 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* nombre */}
          <TextField            
            required
            fullWidth
            margin={"none"}            
            label={"Nombre de obra/faena"}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid> 
        <Grid container item alignItems="center" xs={12} sm={12} md={6} style={gridStyle}> {/* direccion */}
          <TextField            
            required
            fullWidth
            margin={"none"}            
            label={"Dirección de obra/faena"}
            value={formData.address}
            onChange={(e) => setFormValue(e.target.value, "address")}
            inputProps={{ maxLength: 250 }}
          />
        </Grid>   
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* center */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-center-${data ? data.code : "new"}`}>{"Centro de costo"}</InputLabel>
            <Select        
              value={formData.center}
              labelId={`label-center-${data ? data.code : "new"}`}
              id={`select-center-${data ? data.code : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "center")}
              margin={"none"}
            >
              {
                centers && centers.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>     
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* project */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-project-${data ? data.code : "new"}`}>{"Proyecto"}</InputLabel>
            <Select        
              value={formData.project}
              labelId={`label-project-${data ? data.code : "new"}`}
              id={`select-project-${data ? data.code : "new"}`}
              onChange={(e) => setFormValue(e.target.value, "project")}
              margin={"none"}
            >
              {
                projects && projects.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>     
      </Grid>      
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>        
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>      
    </Grid>
  );
}

export default Form;
