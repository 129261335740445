import React, { useMemo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createUserMenusAction, updateUserMenusAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { getNavRoutes } from "src/router/routes";
import { GalleryDialog } from "@icarius-common/galleryDialog";
import { ImagePlaceholder } from "@icarius-icons/index";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { useSelector } from "react-redux";
import { getGalleryImages } from "@icarius-common/galleryDialog/selectors";
import useSetImageIndex from "@icarius-common/galleryDialog/components/useSetImageIndex";
import { getAppColor } from "src/app/selectors";
import { deleteImageAction, getGalleryImagesAction, uploadImageAction } from "@icarius-common/galleryDialog/actions";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = ({ data, handleClose }) => {

  const dispatch = useDispatch();

  const color = useSelector(getAppColor);
  const images = useSelector(getGalleryImages);
  const [imageIndex, setImageIndex] = useSetImageIndex(data, images);
  const [imagesDialogIsOpen, setImagesDialogIsOpen] = useState(false);

  useEffect(() => {
    dispatch(getGalleryImagesAction('news'));
  }, [dispatch])

  const create = (dataToSend) => {
    dispatch(createUserMenusAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updateUserMenusAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  const uploadImage = (file) => {
    setImageIndex(null);
    dispatch(uploadImageAction(file, 'news'));
  }

  const deleteImage = (filename) => {
    setImageIndex(null);
    dispatch(deleteImageAction(filename, 'news'));
  }

  const handleSelectImage = (index) => {
    setFormValue(index !== null ? images[index].img : "", 'image');
    setImageIndex(index);
  }

  const siblingList = useMemo(() => {
    const auxArray = [];
    getNavRoutes('E')
      .forEach((item) => {
        auxArray.push({ key: item.parent.path, value: item.parent.title });
        item.children.map((item) => auxArray.push({ key: item.path, value: item.title }));
      });
    return auxArray;
  }, [])

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* codigo */}
            <TextField
              disabled={!Boolean(isCreate)}
              required
              fullWidth
              margin={"none"}
              label={'Código de función'}
              onChange={(e) => setFormValue(e.target.value, "functionCode")}
              value={formData.functionCode}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
            <TextField
              required
              fullWidth
              margin={"none"}
              label={'Nombre de función'}
              value={formData.functionName}
              onChange={(e) => setFormValue(e.target.value, "functionName")}
              inputProps={{ maxLength: 60 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
            <TextField
              required
              type={'number'}
              fullWidth
              margin={"none"}
              label={"Orden"}
              onChange={(e) => setFormValue(e.target.value, "order")}
              value={formData.order}
              inputProps={{ min: "1", step: "1", max: 999 }}
              onBlur={e => {
                if (e.target.value !== "") {
                  e.target.value = (Math.round(e.target.value * 100) / 100).toFixed(0);
                  if (e.target.value > 999) {
                    e.target.value = 999;
                  }

                  setFormValue(e.target.value, 'order');
                }
              }}
              onInput={e => {
                setFormValue(e.target.value, 'order');
              }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* nombre */}
            <TextField
              required
              fullWidth
              margin={"none"}
              label={'Nombre de menú'}
              value={formData.name}
              onChange={(e) => setFormValue(e.target.value, "name")}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* nombre */}
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label`}>{"Menú de ubicación anterior"}</InputLabel>
              <Select
                value={formData.siblingPath}
                labelId={`label`}
                id={`select`}
                onChange={(e) => setFormValue(e.target.value, "siblingPath")}
                margin={"none"}
              >
                {
                  siblingList?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} style={{ width: 220, height: 140, display: "flex", alignItems: "center", justifyContent: "center" }}>
            {
              imageIndex !== null ?
                <img
                  style={{ maxWidth: 220, maxHeight: 140 }}
                  src={images && images.length > 0 ? IMAGES_ENDPOINT + images[imageIndex].img : ""}
                  alt={"imageNotFound"}
                />
                :
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 220, height: 140, backgroundColor: color }}>
                  <ImagePlaceholder fontSize="small" style={{ fill: "var(--icons)" }} />
                </div>
            }
          </Grid>
          <div style={{ marginTop: 10, width: "100%", display: 'flex', justifyContent: 'center' }} onClick={() => setImagesDialogIsOpen(true)}>
            <Typography variant="h6" className="whiteText" style={{ cursor: "pointer", fontWeight: 400, fontSize: 16, textDecoration: "underline" }}>
              {getLocalizedString("groupImagesTitle")}
            </Typography>
          </div>
        </Grid>
        <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
          <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
        </Grid>
      </Grid>
      {
        imagesDialogIsOpen &&
        <GalleryDialog
          imagePreselected={imageIndex}
          open={imagesDialogIsOpen}
          handleClose={() => setImagesDialogIsOpen(false)}
          handleSubmit={(img) => handleSelectImage(img)}
          handleUploadImage={uploadImage}
          handleDeleteUserImage={deleteImage}
        />
      }
    </>
  );
}

export default Form;
