import React from "react";
import ReactApexChart from 'react-apexcharts';
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import { formatNumberExactDecimals } from "@icarius-utils/format";
import { categoria } from "@icarius-utils/colors";

const Pie = (props) => {

  const {
    title,
    data,
    list,
  } = props;

  const { theme } = useSelector(getTheme);

  const chartData = {
    series: data,
    options: {
      chart: {
        type: 'pie',
        height: 350,
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false
        }
      },
      stroke: {
        show: false,
      },
      labels: list,
      tooltip: {
        enabled: true,
        fillSeriesColor: false,
        theme: theme,
        y: {
          formatter: (value) => formatNumberExactDecimals(value, 2),
        },
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          }
        },
        active: {
          filter: {
            type: 'none',
          }
        }
      },
      title: {
        text: title,
        align: 'center',
        margin: 10,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: 'var(--mainText)',
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: 'bottom',
        labels: {
          colors: 'var(--mainText)',
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      colors: categoria.fills,
    },
  };

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type={chartData.options.chart.type}
      height={chartData.options.chart.height}
    />
  )
}

export default Pie;