import React from "react";
import { Typography } from "@material-ui/core";

const NoDataMessage = () => {

  return (
    <div
      style={{
        height: '65vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography className="whiteText" style={{ fontSize: 32 }}>
        No existen descargas de utilidad por el momento
      </Typography>
    </div>
  );
}

export default NoDataMessage;