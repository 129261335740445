import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getVacationDataAction,
  getEmployeeVacationsAction,
  sendVacationsMessageAction,
} from "../actions";
import { getUserData, getIsExportingGrid } from "src/app/selectors";
import {
  getData,
  getEmployeeMiscData,
  getIsLoading,
  getDateFormat,
  getLocale,
  getEmployeeDebitData,
  getEmployeeCreditData,
  getVacationsSchema,
} from "../selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const useVacations = () => {

  const [graphsDialogIsOpen, setGraphsDialogIsOpen] = useState(false);
  const [employeeVacationsDialogIsOpen, setEmployeeVacationsDialogIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [graphDialogData, setGraphDialogData] = useState(null);

  const dispatch = useDispatch();

  const exportingGridStatus = useSelector(getIsExportingGrid);
  const data = useSelector(getData);
  const isLoading = useSelector(getIsLoading);
  const dateFormat = useSelector(getDateFormat);
  const locale = useSelector(getLocale);
  const userData = useSelector(getUserData);
  const debitData = useSelector(getEmployeeDebitData);
  const creditData = useSelector(getEmployeeCreditData);
  const miscData = useSelector(getEmployeeMiscData);
  const vacationsSchema = useSelector(getVacationsSchema);

  useEffect(() => {
    dispatch(getVacationDataAction());
  }, [dispatch])

  const createGraphData = (gridRef) => {
    let canGetEveryRowData = true;
    let filteredRows = [];

    gridRef.api.forEachNodeAfterFilter((row) => {
      if (!(row && row.data)) {
        canGetEveryRowData = false;
      } else {
        filteredRows.push(row);
      }
    });

    if (!canGetEveryRowData || !filteredRows.length) return null;

    const legalOthersVacations = {
      title: "Vacaciones legales y otros",
      data: {
        "Total utilizadas": filteredRows.reduce((a, b) => a + (parseFloat(b.data["debitUsedLegal"]) || 0), 0),
        "Saldo Pendiente": filteredRows.reduce((a, b) => a + (parseFloat(b.data["debitAvailableLegal"]) || 0), 0),
        "Total reservadas para uso a futuro": filteredRows.reduce((a, b) => a + (parseFloat(b.data["creditUnusedLegal"]) || 0), 0),
        "Total de vacaciones legales y otros": filteredRows.reduce((a, b) => a + (parseFloat(b.data["creditLegal"]) || 0), 0),
      },
    };

    const additionalVacations = {
      title: "Vacaciones adicionales",
      data: {
        "Total utilizadas": filteredRows.reduce((a, b) => a + (parseFloat(b.data["debitUsedAditional"]) || 0), 0),
        "Saldo Pendiente": filteredRows.reduce((a, b) => a + (parseFloat(b.data["debitAvailableAditional"]) || 0), 0),
        "Total reservadas para uso a futuro": filteredRows.reduce((a, b) => a + (parseFloat(b.data["creditUnusedAditional"]) || 0), 0),
        "Total de vacaciones adicionales": filteredRows.reduce((a, b) => a + (parseFloat(b.data["creditAditional"]) || 0), 0),
      },
    };

    return [legalOthersVacations, additionalVacations];
  };

  const handleOpenMailDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length < 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: 'Atención',
      msg: '¿Desea enviar un mensaje recordatorio a los colaboradores para que soliciten vacaciones?',
      onConfirm: () => dispatch(sendVacationsMessageAction({ codes: documents.map(item => item.code) })),
    }))
  }

  const handleOpenGraphs = (gridRef) => {
    setGraphDialogData(createGraphData(gridRef));
    setGraphsDialogIsOpen(true);
  };

  const handleCloseGraphs = () => {
    setGraphsDialogIsOpen(false);
    setGraphDialogData(null);
  };

  const handleRowClick = ({ data }) => {
    dispatch(getEmployeeVacationsAction(userData.level, data.code))
      .then((resp) => {
        if (resp.status === 200) {
          setSelectedRow(data);
          setEmployeeVacationsDialogIsOpen(true);
        }
      })
  };

  const handleCloseSecondGrid = () => {
    setEmployeeVacationsDialogIsOpen(false);
    setSelectedRow(null);
  };

  const state = {
    graphsDialogIsOpen,
    employeeVacationsDialogIsOpen,
    selectedRow,
    graphDialogData,
  }

  const handlers = {
    handleOpenMailDialog,
    handleOpenGraphs,
    handleCloseGraphs,
    handleRowClick,
    handleCloseSecondGrid,
  }

  return {
    isLoading: isLoading || exportingGridStatus,
    data,
    dateFormat,
    locale,
    debitData,
    creditData,
    miscData,
    vacationsSchema,
    state,
    handlers,
  }
}

export default useVacations;