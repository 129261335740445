import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWorksAndTasksAction } from "../actions";
import { getLoading, getData, getCenters, getProjects } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { Grid } from "@material-ui/core";
import DataItem from "./data/dataItem";
import { RESET_STATE } from "../actionTypes";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import paths from "@icarius-localization/paths";

const WorksAndTasksSettings = ({ history }) => {

  const [createIsOpen, setCreateIsOpen] = useState(false);

  const dispatch = useDispatch();

  const color = useSelector(getAppColor);
  const data = useSelector(getData);
  const centers = useSelector(getCenters);
  const projects = useSelector(getProjects);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getWorksAndTasksAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const getDataIsEmpty = () => {
    return !data || data.length === 0;
  }
  const handleGoBack = () => {
    history.push(paths.locationSettingsBox);
  }
  return (
    <ABMLayout
      title={"Definición de obras/faenas"}
      isLoading={isLoading}
      showCreateButton={!createIsOpen}
      createButtonTitle={"Nueva obra/faena"}
      createButtonOnClick={() => setCreateIsOpen(true)}
      showNoData={getDataIsEmpty()}
      noDataTitle={"No se encontraron obras/faenas"}
      noDataCreateTitle={"Nueva obra/faena"}
      noDataOnClick={() => setCreateIsOpen(true)}
      showGoBack
      handleGoBack={handleGoBack}
    >
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <DataItem
            color={color}
            data={null}
            centers={centers}
            projects={projects}
            closeNew={() => setCreateIsOpen(false)}
          />
        </Grid>
      }
      {
        data && data.map((item) => {
          return (
            <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.code}>
              <DataItem data={item} centers={centers} projects={projects} color={color} />
            </Grid>
          )
        })
      }
    </ABMLayout>
  )
}

export default WorksAndTasksSettings;