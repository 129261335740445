import { useState } from "react";
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { formatNumberOrReturnUndefined, zeroPad } from "@icarius-utils/format";

const useHandleForm = (data, vacationsSchema, createCallback, modifyCallback, invalidDataCallback) => {
  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (isCreate) return "";
      if (fieldName.includes("_days")) {
        return formatNumberOrReturnUndefined(data[fieldName], 0, 3)?.replace(",", ".") || formatNumberOrReturnUndefined(data[fieldName], 0, 3);
      }
      return data[fieldName];
    }

    const fieldNames = [
      'year',
      'month',
      'legalDays',
      'additionalDays',
      'otherDays',
      'description'
    ];

    const fieldMapper = {
      'year': 'year',
      'month': 'months',
      'legalDays': 'legal_days',
      'additionalDays': 'additional_days',
      'otherDays': 'other_days',
      'description': 'description'
    }

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldMapper[fieldName])
      })
    }, {});
  }

  const dispatch = useDispatch();

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'year': yup.string().max(9).required(),
      'month': yup.number().min(0).max(12).required(),
      'legalDays': yup.number().min(0).required(),
      'additionalDays': yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      'otherDays': yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null),
      'description': yup.string().min(0).max(100),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (isCreate) {
      if (vacationsSchema === "A" && !(/^(19|20)\d{2}$/.test(formData["year"]))) {
        dispatch(openSnackbarAction({ msg: "En año es necesario que ingrese un año válido, Ej: 2022", severity: "error" }));
        return;
      }

      if (vacationsSchema === "P" && !(/^(19|20)\d{2}\/(19|20)\d{2}$/.test(formData["year"]))) {
        dispatch(openSnackbarAction({ msg: "En año es necesario que ingrese un período anual válido, Ej: 2022/2023", severity: "error" }));
        return;
      }
    }

    if (formData["month"] <= 0) {
      dispatch(openSnackbarAction({ msg: "Los meses deben ser entre 1 y 12", severity: "error" }));
      return;
    }

    if (formData["legalDays"] <= 0) {
      dispatch(openSnackbarAction({ msg: "Los días legales deben ser mayor a cero", severity: "error" }));
      return;
    }

    if (!(/^\d+(\.\d{0,3})?$/.test(String(formData["legalDays"])))) {
      dispatch(openSnackbarAction({ msg: "Días legales puede contener hasta 3 decimales", severity: "error" }));
      return;
    }

    if (String(formData["additionalDays"]) && !(/^\d+(\.\d{0,3})?$/.test(String(formData["additionalDays"])))) {
      dispatch(openSnackbarAction({ msg: "Días adicionales puede contener hasta 3 decimales", severity: "error" }));
      return;
    }

    if (String(formData["otherDays"]) && !(/^\d+(\.\d{0,3})?$/.test(String(formData["otherDays"])))) {
      dispatch(openSnackbarAction({ msg: "Otros puede contener hasta 3 decimales", severity: "error" }));
      return;
    }

    if (String(formData["description"]).length <= 0) {
      dispatch(openSnackbarAction({ msg: "La descripción no puede estar vacía", severity: "error" }));
      return;
    }

    if (await dataIsValid()) {
      let dataToSend = {
        'year': formData["year"],
        'month': formData["months"] || formData["month"],
        'legalDays': formData["legal_days"] || formData["legalDays"],
        'additionalDays': formData["additional_days"] || formData["additionalDays"],
        'otherDays': formData["other_days"] || formData["otherDays"],
        'description': formData["description"],
      };

      if (dataToSend.month) {
        dataToSend.month = zeroPad(dataToSend.month, 2);
      }

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
