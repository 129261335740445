import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";

const Item = ({ title, value, hasLine, hasColor }) => {
  const color = useSelector(getAppColor);

  return (
    <Grid container item xs={12} alignItems='flex-end'>
      <Grid container item xs={9} alignItems='center' style={{ borderBottom: hasLine ? `1px solid var(--mainText)` : '' }}>
        <Typography
          style={{
            fontSize: "1.2rem",
            lineHeight: "1.4",
            color: hasColor ? color : 'var(--mainText)',
            fontWeight: hasColor ? 700 : 400
          }}
          variant="subtitle1"
        >
          {title}
        </Typography>
      </Grid>
      <Grid container alignItems='center' justify="flex-end" item xs={3} style={{ borderBottom: hasLine ? `1px solid var(--mainText)` : '' }}>
        <Typography
          style={{
            fontSize: "1.2rem",
            lineHeight: "1.4",
            color: hasColor ? color : 'var(--mainText)',
            fontWeight: hasColor ? 700 : 400
          }}
          variant="subtitle1"
        >
          {formatNumberOrReturnUndefined(value, 0, 2)}
        </Typography>
      </Grid>
    </Grid>
  )
}

const BalanceData = ({ data }) => {

  return (
    <Grid container item xs={12} style={{ height: "65vh" }}>
      <Grid container item xs={12} style={{ padding: 10 }}>
        <Item title={'Total días legales y otros'} value={data.credit_total_legal_days + data.credit_total_other_days} />
        <Item title={'Total de días adicionales o progresivos'} value={data.credit_total_additional_days} hasLine />
        <Item title={'Total de días ganados'} value={data.credit_total_days} />
      </Grid>
      <Grid container item xs={12} style={{ padding: 10 }}>
        <Item title={getLocalizedString("usedLegalDays")} value={data.debit_used_legal_days} />
        <Item title={'Días utilizados (adicionales o progresivos)'} value={data.debit_used_additional_days} hasLine />
        <Item title={'Total de días utilizados'} value={data.debit_used_days} />
      </Grid>
      <Grid container item xs={12} style={{ padding: 10 }}>
        <Item title={'Días por utilizar a futuro (legales y otros)'} value={data.credit_total_unused_legal_days} />
        <Item title={'Días por utilizar a futuro (adicionales o progresivos)'} value={data.credit_total_unused_additional_days} hasLine />
        <Item title={'Total de días por utilizar a futuro'} value={data.credit_unused_days} />
      </Grid>
      <Grid container item xs={12} style={{ padding: 10 }}>
        <Item title={getLocalizedString("legalOthersBalance")} value={data.debit_legal_available_days} />
        <Item title={'Saldo adicionales o progresivos'} value={data.debit_additional_available_days} hasLine />
        <Item title={getLocalizedString("totalAvailableBalance")} value={data.debit_total_available_days} hasColor />
      </Grid>
      <Grid container item xs={12} style={{ padding: 10 }}>
        <Item title={getLocalizedString("totalPlusUnusedAvailableBalance")} value={data.debit_total_available_days + data.credit_unused_days} />
      </Grid>
    </Grid>
  )
}

export default BalanceData;