import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  data: [],
  debitData: [],
  creditData: [],
  miscData: {},
  vacationsSchema: "",
  locale: "es",
  dateFormat: "yyyy/mm/dd",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        dateFormat: action.payload.dateFormat,
        data: action.payload.result,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.EMPLOYEE_VACATIONS:
      return { ...state, isLoading: true };
    case actionTypes.EMPLOYEE_VACATIONS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        debitData: action.payload.debitData,
        creditData: action.payload.creditData,
        miscData: action.payload.miscData,
        vacationsSchema: action.payload.vacationsSchema,
        dateFormat: action.payload.dateFormat,
        locale: action.payload.locale,
      };
    case actionTypes.EMPLOYEE_VACATIONS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DOWNLOAD:
      return { ...state, isLoading: true };
    case actionTypes.DOWNLOAD_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.DOWNLOAD_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.SEND_MAIL:
      return { ...state, isLoading: true };
    case actionTypes.SEND_MAIL_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.SEND_MAIL_REJECTED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}