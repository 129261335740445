import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import CommonPage from "@icarius-common/commonPage";
import { Typography, Divider } from "@material-ui/core";
import NoDataMessage from "./noDataMessage";
import useUtils from "./useUtils";

const Utils = () => {

  const {
    data,
    isLoading,
  } = useUtils();
  const color = useSelector(getAppColor);

  return (
    <CommonPage
      title={'Descargas de utilidad'}
      isLoading={isLoading}
      isNotGridPage
    >
      {
        !Boolean(data.length) &&
        <NoDataMessage />
      }
      {
        !isLoading &&
        data?.map((item, index) => {
          if (!item.data.length) return null;
          return (
            <section key={item.group} style={{ paddingBottom: index !== (data.length - 1) ? 10 : 0 }}>
              <div className={"slider-container"}>
                <Typography className={"title"}>
                  {item.group}
                </Typography>
                <Divider style={{ background: color, height: 2 }} />
              </div>
              <ScreenSelector data={item.data} />
            </section>
          )
        })
      }
    </CommonPage>
  );
}

export default Utils;